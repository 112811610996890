import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchDocumentCategories } from "graphql/queries/documents";

export const getDocumentsCategories = createAsyncThunk(
  "documents/getCategories",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await fetchDocumentCategories();
      return data.documentCategories;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
